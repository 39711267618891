import { TimelinePayload } from '~/common/types/types';

const timelinePayload: TimelinePayload = {
  badgeText: 'timeline.badge',
  sections: [
    {
      title: 'timeline.section0.title',
      subtitle: 'timeline.section0.subtitle',
      descriptor: {
        text: 'timeline.section0.descriptor',
        position: 'right',
      },
      items: [
        {
          title: 'timeline.section0.group0.title',
          description: 'timeline.section0.group0.description',
          dateStart: '2025-03-03',
          dateEnd: '2025-04-25',
          dateSeparator: null,
        },
        {
          title: 'timeline.section0.group1.title',
          description: 'timeline.section0.group1.description',
          dateStart: '2025-04-26',
          dateEnd: '2025-04-29',
          dateSeparator: 'timeline.section0.group1.dateSeparator',
        },
        {
          title: 'timeline.section0.group2.title',
          description: 'timeline.section0.group2.description',
          dateStart: '2025-05-02',
          dateEnd: '2025-05-13',
          dateSeparator: null,
        },
      ],
    },
    {
      title: 'timeline.section1.title',
      subtitle: 'timeline.section1.subtitle',
      descriptor: {
        text: 'timeline.section1.descriptor',
        position: 'left',
      },
      sectionHighlight: 'secondary',
      items: [
        {
          title: 'timeline.section1.group0.title',
          description: 'timeline.section1.group0.description',
          dateStart: '2025-05-28',
          dateEnd: '2025-07-10',
          dateSeparator: null,
        },
        {
          title: 'timeline.section1.group1.title',
          description: 'timeline.section1.group1.description',
          dateStart: '2025-05-28',
          dateEnd: '2025-07-10',
          dateSeparator: null,
        },
        {
          title: 'timeline.section1.group2.title',
          description: 'timeline.section1.group2.description',
          dateStart: '2025-07-18',
          dateEnd: '2025-08-29',
          dateSeparator: null,
        },
        {
          title: 'timeline.section1.group3.title',
          description: 'timeline.section1.group3.description',
          dateStart: '2025-08-30',
          dateEnd: null,
          dateSeparator: null,
        },
      ],
    },
  ],
};

export { timelinePayload };
